.st_breadcrumb_content{
	ul{
		li{
			line-height: 1;
			display: inline-block;
			position: relative;
			&.breadcrumb-seperator {
				display: inline-block;
				padding: 7.5px 7px 7.5px 2px;
				top: 3.2px;
				text-align: center;
				&::after {
					content: "/";
					display: block;
					color: currentColor;
					position: absolute;
					top: 2.5px;
					transform: rotate(6deg);
					font-size: 0.8em;
				}
			}
			a{
				@include font-size(14);
			}
		}
	}
}

.st_breadcrumb_wrapper.st_breadcrumb_2{
	background-size: cover;
	background-position: center center;
	position: relative;
	z-index: 0;
	&:before{
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		background: -moz-linear-gradient(left, rgba(28,72,222,0.8) 0%, rgba(0,227,246,0.8) 100%);
		background: -webkit-linear-gradient(left, rgba(28,72,222,0.8) 0%,rgba(0,227,246,0.8) 100%);
		background: linear-gradient(to right, rgba(28,72,222,0.8) 0%,rgba(0,227,246,0.8) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc1c48de', endColorstr='#cc28a745',GradientType=1 );
	}
}
.st_breadcrumb_wrapper.st_breadcrumb_3{
	background-repeat: no-repeat;
	background-position: center bottom;
}