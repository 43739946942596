%gigas-icon-gradient {
	background: #5040a0;
	background: -moz-linear-gradient(-45deg, #5040a0 0%, #d2caf9 100%);
	background: -webkit-linear-gradient(-45deg, #5040a0 0%, #d2caf9 100%);
	background: linear-gradient(135deg, #5040a0 0%, #d2caf9 100%);
	background: linear-gradient(135deg, #5040a0 0%, #d2caf9 51%, #5040a0 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5040a0', endColorstr='#d2caf9',GradientType=0 );
  background-size: 230% auto;
}
%orange-icon-gradient {
  background: rgb(255,93,28);
  background: -moz-linear-gradient(318deg, rgba(255,93,28,1) 0%, rgba(254,149,69,1) 100%);
  background: -webkit-linear-gradient(318deg, rgba(255,93,28,1) 0%,rgba(254,149,69,1) 100%);
  background: linear-gradient(318deg, rgba(255,93,28,1) 0%,rgba(254,149,69,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5d1c', endColorstr='#fe9545',GradientType=1 );
}
%line-gradient {
  background: rgb(80,64,160);
  background: -moz-linear-gradient(left, rgba(237,233,255,1) 0%, rgba(80,64,160,1) 50%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(left, rgba(237,233,255,1) 0%,rgba(80,64,160,1) 50%,rgba(255,255,255,1) 100%);
  background: linear-gradient(to right, rgba(237,233,255,1) 0%,rgba(80,64,160,1) 50%,rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ede9ff',GradientType=1 );
}
%aqua-line-gradient {
  background: rgb(0, 227, 246);
  background: -moz-linear-gradient(left, rgba(237,233,255,1) 0%, rgba(0, 227, 246,0.7) 50%, rgba(255,255,255,0.4) 100%);
  background: -webkit-linear-gradient(left, rgba(237,233,255,1) 0%,rgba(0, 227, 246,0.7) 50%,rgba(255,255,255,0.4) 100%);
  background: linear-gradient(to right, rgba(237,233,255,1) 0%,rgba(0, 227, 246,0.7) 50%,rgba(255,255,255,0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ede9ff',GradientType=1 );
}
%gigas-gradient {
	background: #8066dc;
  background: -moz-linear-gradient(top, #8066dc 0%, #5040a0 100%);
  background: -webkit-linear-gradient(top, #8066dc 0%, #5040a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8066dc', endColorstr='#5040a0',GradientType=0 );
}
%gigas-btn-gradient {
	background: -moz-linear-gradient(left, #5040a0 0%, #8066dc 100%);
  background: -webkit-linear-gradient(left, #5040a0 0%, #8066dc 100%);
  background: linear-gradient(to right, #5040a0 0%, #8066dc 51%, #5040a0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5040a0', endColorstr='#8066dc',GradientType=1 );
  background-size: 200% auto;
}
%emerald-btn-gradient {
  background: #8dc059;
  background: -moz-linear-gradient(-45deg, #8dc059 0%, #40d1a7 100%);
  background: -webkit-linear-gradient(-45deg, #8dc059 0%, #40d1a7 100%);
  background: linear-gradient(135deg, #8dc059 0%, #40d1a7 100%);
  background: linear-gradient(to right, #8dc059 0%, #40d1a7 51%, #8dc059 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dc059', endColorstr='#40d1a7',GradientType=1 );
  background-size: 200% auto;
}
%aqua-gredient-color {
  background: #1e3bdd;
  background: -moz-linear-gradient(top, #1e3bdd 0%, #01e0f6 100%);
  background: -webkit-linear-gradient(top, #1e3bdd 0%, #01e0f6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e3bdd', endColorstr='#01e0f6',GradientType=0 );
}
%aqua-gredient-bgc {
  background: #1c48de;
  background: -moz-linear-gradient(left, #1c48de 0%, #28a745 100%);
  background: -webkit-linear-gradient(left, #1c48de 0%, #28a745 100%);
  background: linear-gradient(to right, #1c48de 0%, #28a745 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c48de', endColorstr='#28a745',GradientType=1 );
}
%aqua-tab-gradient {
  background: #1e3bdd;
  background: -moz-linear-gradient(-45deg, #1e3bdd 0%, #01e0f6 100%);
  background: -webkit-linear-gradient(-45deg, #1e3bdd 0%, #01e0f6 100%);
  background: linear-gradient(135deg, #1e3bdd 0%, #01e0f6 100%);
  background: linear-gradient(to right, #1e3bdd 0%, #01e0f6 51%, #1e3bdd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e3bdd', endColorstr='#01e0f6',GradientType=1 );
}
%aqua-gradient-bg2 {
  background: #1C48DE;
  background: -moz-linear-gradient(left, #1C48DE 0%, #28a745 100%);
  background: -webkit-linear-gradient(left, #1C48DE 0%, #28a745 100%);
  background: linear-gradient(to right, #1C48DE 0%, #28a745 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c48de', endColorstr='#28a745',GradientType=1 );
}
%primary-gredient-color {
  background: #1c48de;
  background: -moz-linear-gradient(top, #1c48de 0%, $color-aqua 100%);
  background: -webkit-linear-gradient(top, #1c48de 0%,$color-aqua 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c48de', endColorstr='$color-aqua',GradientType=0 );
}
%polar-gradient-background {
  background: $color-white;
  background: -moz-linear-gradient(top, $color-white 0%, $color-polar 50%, #e5f2ff 100%);
  background: -webkit-linear-gradient(top, $color-white 0%, $color-polar 50%, #e5f2ff 100%);
  background: linear-gradient(to bottom, $color-white 0%, $color-polar 50%, #e5f2ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='#e5f2ff',GradientType=0 );
}
%orange-gradient-menu {
  background: $color-orange;
  background: -moz-linear-gradient(left, $color-orange 0%, #fe9545 100%);
  background: -webkit-linear-gradient(left, $color-orange 0%, #fe9545 100%);
  background: linear-gradient(to right, $color-orange 0%, #fe9545 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c48de', endColorstr='#28a745',GradientType=1 );
}
%orange-gradient-banner {
  background: $color-orange;
  background: -moz-linear-gradient(top, $color-orange 0%, #fe9545 100%);
  background: -webkit-linear-gradient(top, $color-orange 0%, #fe9545 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-orange', endColorstr='#fe9545',GradientType=0 );
}
%aqua-pricing-bg {
  background: #1c48de;
  background: -moz-linear-gradient(top, #1c48de 0%, #28a745 100%);
  background: -webkit-linear-gradient(top, #1c48de 0%, #28a745 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c48de', endColorstr='$color-aqua',GradientType=0 );
}
%orange-pricing-bg {
  background: $color-orange;
  background: -moz-linear-gradient(top, $color-orange 0%, #fe9545 100%);
  background: -webkit-linear-gradient(top, $color-orange 0%, #fe9545 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-orange', endColorstr='#fe9545',GradientType=0 );
}
%froly-gradient-bg {
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #fff8f8 50%, #fff1f1 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #fff8f8 50%, #fff1f1 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #fff8f8 50%, #fff1f1 100%);
}
%orange-deep-gradient {
  background: #FE9545;
  background: -moz-linear-gradient(left, #FE9545 0%, #FF5D1C 100%);
  background: -webkit-linear-gradient(left, #FE9545 0%, #FF5D1C 100%);
  background: linear-gradient(to right, #FE9545 0%, #FF5D1C 51%, #FE9545 100%);
  background-size: 200% auto;
}
%wave-curved-bg {
  background: #F5F9FD;
  background: -moz-linear-gradient(top, #F5F9FD 0%, #FFFFFF 100%);
  background: -webkit-linear-gradient(top, #F5F9FD 0%, #FFFFFF 100%);
}
.wave-curved-bg {
  margin-top: 200px;
  padding-top: 100px;
  position: relative;
  @extend %wave-curved-bg;
  &::before {
    width: 100%;
    height: 420px;
    position: absolute;
    left: 0;
    top: -420px;
    content: "";
    background-image: url(../images/v14/about-shape.png);
    background-position: top center;
    z-index: -1;
  }
  @include media-max(MD) {
    margin-top: 50px;
  }
  @include media-max(SM) {
    padding-top: 50px;
  }
}
.line-gradient {
  @extend %line-gradient;
}
.aqua-gredient-color {
  @extend %aqua-gredient-color;
}
.orange-deep-gradient {
  @extend %orange-deep-gradient;
}
.orange-gradient-banner {
  @extend %orange-gradient-banner;
}
.polar-gradient-background {
  @extend %polar-gradient-background;
}
.aqua-gredient-background {
  @extend %aqua-gredient-bgc;
}
.aqua-price-gradient {
  background-image: -webkit-linear-gradient(to right, #1c48de, #28a745);
  background-image: -moz-linear-gradient(to right, #1c48de, #28a745);
  background-image: linear-gradient(to right, #1c48de, #28a745);
}
.aqua-line-gradient {
  @extend %aqua-line-gradient;
}
.aqua-gredient-bg-2 {
  @extend %aqua-gredient-bgc;
}
.bg-gigas-gradient {
  @extend %gigas-gradient;
}
.bg-emerald-gradient,
.st_default_btn.bg-emerald-gradient {
  @extend %emerald-btn-gradient;
}
.bg-gigas-gradient-btn,
.st_default_btn.bg-gigas-gradient-btn {
  @extend %gigas-btn-gradient;
}
.st_default_btn.bg-emerald-gradient:hover,
.st_default_btn.bg-emerald-gradient:focus,
.st_default_btn.bg-emerald-gradient:active,
.st_default_btn.bg-gigas-gradient-btn:hover,
.st_default_btn.bg-gigas-gradient-btn:focus,
.st_default_btn.bg-gigas-gradient-btn:active {
  background-position: right center;
  color: $color-white;
}
.st-s-gredient-bg {
  padding: 290px 0 150px;
  background: $color-white;
  background: -moz-linear-gradient(top, $color-white 0%, #eceeff 50%, #e8eaff 100%);
  background: -webkit-linear-gradient(top, $color-white 0%, #eceeff 50%, #e8eaff 100%);
  background: linear-gradient(to bottom, $color-white 0%, #eceeff 50%, #e8eaff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-white', endColorstr='#e8eaff',GradientType=0 );
  -webkit-mask-image: url(../images/footer-bg.png);
  mask-image: url(../images/footer-bg.png);
  mask-repeat: no-repeat;
  mask-size: 100% auto;
  mask-position: top center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% auto;
  -webkit-mask-position: top center;
  @include media-max(LG) {
    padding: 250px 0 110px;
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
    .align-items-end {
      align-items: flex-start!important;
    }
  }
  @include media-max(SM) {
    padding: 110px 0 60px;
    -webkit-mask-image: none;
    mask-image: none;
  }
}
%ash-gradient-bg {
  background: $color-zircon;
  background: -moz-linear-gradient(left, $color-zircon 0%, $color-zircon 100%);
  background: -webkit-linear-gradient(left, $color-zircon 0%, $color-zircon 100%);
  background: linear-gradient(to right, $color-zircon 0%, $color-zircon 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-zircon', endColorstr='$color-zircon',GradientType=1 );
}
header.sticky-menu.sticky-aqua-gradient {
  @extend %aqua-gredient-bgc;
}
header.sticky-menu.sticky-ash-gradient {
  @extend %ash-gradient-bg;
}
header.sticky-menu.sticky-gigas-gradient {
  @extend %gigas-btn-gradient;
}
header.sticky-menu.sticky-orange-gradient {
  @extend %orange-gradient-menu;
}
.black-alpha-bg {
  background: rgba(0,0,0,0.37);
}
header.sticky-menu.sticky-black-alpha-bg {
  background: rgba(0,0,0,0.37);
}
header.sticky-menu.white-alpha-bg {
  background: rgba(255,255,255,0.37);
}
header.sticky-menu.sticky-white-alpha-bg {
  background: rgba(255,255,255,0.37);
}
// apply background color 
@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color!important;
  }
  .rgba-#{$name} {
    background-color: rgba($color, 0.1)!important;
  }
  .text-#{$name} {
    color: $color!important;
  }
}

@each $name, $color in $colors {
  .st_default_btn.bg-#{$name},
  .st_default_btn.st_transparent.bg-#{$name},
  .feature_item_icon.bg-#{$name} {
    background-color: $color !important;
  }
  .feature_item_icon.bg-#{$name} {
    box-shadow: 0 10px 30px rgba($color, 0.3);
  }
  .st_info_box.bg-#{$name} {
    background-color: $color !important;
  }
  .st_info_box.bg-shadow-#{$name} {
    box-shadow: 0 30px 60px rgba($color, 0.4);
  }
  .st-mean-#{$name}.mean-container {
    a.meanmenu-reveal {
      color: $color !important;
      span {
        background-color: $color !important;
      }
    } 
  } 
}


// apply text color
@each $name, $color in $colors {
  .st_default_btn.text-#{$name},
  .st_default_btn.st_transparent.text-#{$name} {
    color: $color !important;
  }
  .st_default_btn.st_transparent.text-#{$name}:hover, 
  .st_default_btn.st_transparent.text-#{$name}:focus,
  .st_default_btn.st_transparent.text-#{$name}:active {
    background-color: $color !important;
    border-color: $color !important;
  }
  .border-#{$name} {
    border-color: $color !important;
  }
}


// apply background color 
@each $name, $color in $colors {
  .bg-hover-#{$name}:hover,
  .bg-hover-#{$name}:focus,
  .bg-hover-#{$name}:active,
  .st_default_btn.bg-hover-#{$name}:hover,
  .st_default_btn.bg-hover-#{$name}:focus,
  .st_default_btn.bg-hover-#{$name}:active {
    background-color: $color!important;
  }
  header.sticky-menu.sticky-bg-#{$name} {
    background: $color!important;
  }
  .text-hover-#{$name}:hover,
  .text-hover-#{$name}:focus,
  .text-hover-#{$name}:active,
  .st_default_btn.st_transparent.text-hover-#{$name}:hover,
  .st_default_btn.st_transparent.text-hover-#{$name}:focus,
  .st_default_btn.st_transparent.text-hover-#{$name}:active  {
    color: $color !important;
  }
  .fieldset-active-#{$name} {
    input:checked + label {
      color: $color !important;
    }
    .st-pricing-underlines {
      .st-pricing-underlines-1 {
        background: -moz-linear-gradient(left, rgba(255,255,255,0.8) 0%, rgba($color, 0.1) 20%, rgba($color, 0.1) 80%, rgba(255,255,255,0.8) 100%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,0.8) 0%,rgba($color, 0.1) 20%, rgba($color, 0.1) 80%,rgba(255,255,255,0.8) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba($color, 0.1) 20%, rgba($color, 0.1) 80%, rgba(255, 255, 255, 0.8) 100%);
      }
      .st-pricing-underlines-2 {
        background: $color;
        background: -moz-linear-gradient(left, rgba($color, 0.04) 0%, $color 50%, rgba(255,255,255,0.1) 100%);
        background: -webkit-linear-gradient(left, rgba($color, 0.04) 0%, $color 50%, rgba(255,255,255,0.1) 100%);
        background: linear-gradient(to right, rgba($color, 0.04) 0%, $color 50%, rgba(255,255,255,0.1) 100%);
      }
    }
  } 
}

.st_default_btn.aqua-gredient-background {
  @extend .aqua-gredient-background;
}


@each $breakpoint in $breakpoints {
  @include media-max(to-upper-case($breakpoint)) {
    
    @each $name, $color in $colors {
      .bg-#{$breakpoint}-#{$name} {
        background-color: $color!important;
      }
      .rgba-#{$breakpoint}-#{$name} {
        background-color: rgba($color, 0.1)!important;
      }
      .text-#{$breakpoint}-#{$name} {
        color: $color!important;
      }
    }

    .bg-#{$breakpoint}-none {
      background: none !important;
    }
    .bgc-#{$breakpoint}-none {
      background-color: transparent !important;
    }
    .bgi-#{$breakpoint}-none {
      background-image: none !important;
    }
    .br-#{$breakpoint}-none {
      br {
        display: none !important;
      }
    }
  }
}

.sticky-menu.sticky-box-shadow {
  box-shadow: 0 1px 18px rgba(0,0,0,0.04);
  @include media-max(MD) {
    box-shadow: 0 1px 8px rgba(0,0,0,0.04);
  }
}
