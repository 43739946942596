//
// = Screenshot Slider styles
//
// @package: SassTrend
// @version: 1.0
//
// ------------------------------------------------------------------------- */
.st_swiper_slider {
  position: relative;
  .st_screenshot {
	  overflow: hidden;
	  padding: 40px 0;
    .swiper-slide.swiper-slide-prev{
      filter: blur(2px);
    }
    .swiper-slide.swiper-slide-next {
      filter: blur(3px);
      opacity: 0.4;
    }
    .swiper-slide.swiper-slide-active {
      box-shadow: 0 10px 60px rgba(39, 39, 39, 0.05);
    }
    .swiper-slide{
      img{
        width: 100%;
      }
      .swiper-slide-shadow-right, .swiper-slide-shadow-left {
        background-image: none;
      }
    }
	}
	.swiper-slide {
		img {
			width: 100%;
		}
		.swiper-slide-shadow-right,
		.swiper-slide-shadow-left {
			background-image: none;
		}
	}
  @include media-max(MD) {
    padding-top: 0;
  }
  @include media-max(SM) {
    padding: 0;
  }
}
.swiper-button-prev, 
.swiper-button-next {
  background-image: none;
  border-radius: 100%;
  background-color: $color-polar;
  color: $color-medium-purple;
  font-size: 1.5em;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  position: absolute;
  opacity: 1;
  left: -90px;
  top: 50%;
  @include transform(translateY(-50%));
  z-index: 1;
  outline: none;
  -webkit-text-stroke: 1.5px $color-polar;
  @include transition();
  
  @include media-max(MD) {
    background: $color-gigas;
    color: $color-white;
    box-shadow: 0 10px 30px rgba(80, 64, 160, 0.3);
    -webkit-text-stroke: 1.5px $color-gigas;
    display: none !important;
  }
}
.swiper-button-next {
  left: auto;
  right: -90px;
}
@include media-max(LG) {
  div.swiper-button-prev {
    left: 0;
  }
  div.swiper-button-next {
    right: 0;
  }
}
.swiper-button-prev:hover, 
.swiper-button-next:hover {
  background: $color-gigas;
  color: $color-white;
  box-shadow: 0 10px 30px rgba(80, 64, 160, 0.3);
  -webkit-text-stroke: 1.5px $color-gigas;
}

.st_screenshot_wrap {
  position: relative;
}
.st_screenshot_v2{
  overflow: hidden;
  border-left: 15px solid rgba(242, 243, 245, 0.85);
  border-right: 15px solid rgba(242, 243, 245, 0.85);
  width: calc(100% - 140px);
  margin: auto;
  .swiper-slide.swiper-slide-prev{
    transform: translateX(500px) !important;
    opacity: .85;
  }
  .swiper-slide.swiper-slide-next {
    transform: translateX(-500px) !important;
    opacity: .85;
  }
  .swiper-slide{
    box-shadow: 0 0 50px rgba(39, 39, 39, 0.08);
    img{
      width: 100%;
    }
    .swiper-slide-shadow-right, .swiper-slide-shadow-left {
      background-image: none;
    }
  }
  @include media-max(MD) {
    width: calc(100% - 60px);
  }
  @include media-max(SM) {
    width: 100%;
  }
}
.st_screenshot_v2 + .swiper-button-prev, 
.st_screenshot_v2 + .swiper-button-prev + .swiper-button-next{
  @include st_slider_icon($bgc: #fff,$color: #28a745,$font_size: 1.5em,$width: 55px,$height: 55px,$top: 50%,$opacity: 1,$border: none,$left: 0);
  background-color: #fff;
  z-index: 1;
  -webkit-text-stroke:1.5px #FFFFFF;
  &:hover{
    background-color: #28a745;
    color: #FFFFFF;
    box-shadow: 0 10px 30px rgba(0, 227, 246, 0.3);
    -webkit-text-stroke:1.5px #28a745;
  }
}
.st_screenshot_v2 + .swiper-button-prev + .swiper-button-next{
  left: auto;
  right: 0;
}


.st_screenshot_area {
  @include media-max(MD) {
    overflow-x: hidden;
  }
  .st_screenshot_warap {
    &.st_screenshot_warap_v3 {
      .st_screenshot_tab {
        position: relative;
        margin-bottom: 75px;
        &:before{
          width: 100%;
          height: 6px;
          position: absolute;
          left: 0;
          top: 45px;
          content: "";
          background: -moz-linear-gradient(left, rgba(255,255,255,0.8) 0%, rgba(255,247,246,0.8) 49%, rgba(255,247,246,0.8) 50%, rgba(255,255,255,0.8) 100%); 
          background: -webkit-linear-gradient(left, rgba(255,255,255,0.8) 0%,rgba(255,247,246,0.8) 49%,rgba(255,247,246,0.8) 50%,rgba(255,255,255,0.8) 100%);
          background: linear-gradient(to right, rgba(255,255,255,0.8) 0%,rgba(255,247,246,0.8) 49%,rgba(255,247,246,0.8) 50%,rgba(255,255,255,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#ccffffff',GradientType=1 );
        }
        .nav-pills {
          > li {
            display: inline-block;
            margin: 0 25px;
            text-transform: capitalize;
            @include media-max(MD) {
              margin: 0 15px;
            }
            a {
              padding: 15px 26px;
              &.active {
                color: $color-froly;
                @extend %froly-gradient-bg;
                &:before{
                  content: none;
                }
              }
              @include media-max(MD) {
                padding: 0;
                background:none;
              }
            }
          }
        }
      }
    }
  }
}




// screenshot center frame
.st_swiper_slider_center_frame {
  position: relative;
  padding: 75px 0;
  @include media-max(SM) {
    width: 80%;
    margin: auto;
    padding: 0;
  }
  .st_swiper_frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 385px;
    text-align: center;
    @include media-max(MD) {
      display: none;
    }
  }
  .st_screenshot_center_frame{
    overflow: hidden;
    .swiper-slide{
      box-shadow: 10px 0 80px rgba(0, 0, 0, 0.05);
      img{
        width: 100%;
      }
      .swiper-slide-shadow-right, .swiper-slide-shadow-left {
        background-image: none;
      }
    }
    .swiper-slide-nth-prev-2 {
      transform: translate3d(245px, 0px, 0px) !important;
    }
    .swiper-slide-nth-prev-3 {
      transform: translate3d(416px, 0px, 0px) !important;
    }
    .swiper-slide-nth-next-2 {
      transform: translate3d(-245px, 0px, 0px) !important;
    }
    .swiper-slide-nth-next-3 {
      transform: translate3d(-416px, 0px, 0px) !important;
    }
  }
  .swiper-button-prev, 
  .swiper-button-next{
    border-radius: 100%;
    background-color: $color-white;
    color: $color-aqua;
    font-size: 1.5em;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    position: absolute;
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    -webkit-text-stroke: 1.5px $color-polar;
    @include transition();
    &:hover{
      background: $color-aqua;
      color: $color-white;
      box-shadow: 0 10px 30px rgba(0, 227, 246, 0.3);
      -webkit-text-stroke: 1.5px $color-aqua
    }
  }
  .swiper-button-prev {
    left: -90px;
  }
  .swiper-button-prev.swiper-buton-style-v2,
  .swiper-button-next.swiper-buton-style-v2 {
    background-color: $color-white;
    color: $color-orange;
    -webkit-text-stroke: 1.5px $color-white;
    &:hover,
    &:active,
    &:focus {
      background-color: $color-orange;
      color: $color-white;
      -webkit-text-stroke: 1.5px $color-orange;
    }
  }
  .swiper-button-next{
    right: -90px;
  }
  @include media-max(LG) {
    div.swiper-button-prev {
      left: 0;
    }
    div.swiper-button-next {
      right: 0;
    }
  }
  @include media-max(SM) {
    div.swiper-button-prev {
      display: none;
    }
    div.swiper-button-next {
      display: none;
    }
  }
}



@include media-max(SM) {
  .swiper-button-prev, 
  .swiper-button-next{
    @include transform(translateY(0));
    margin-top: -26.5px;
    width: 45px;
    height: 45px;
  }
}